html {
  background-color: #f5f7fa;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  * {
    box-sizing: border-box;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.icon {
  display: inline-block;
  font: normal normal normal 1em/1 'Avıonexus';
  color: inherit;
  flex-shrink: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* size examples - relative units */
.icon-sm {
  font-size: 16px;
}

.icon-md {
  font-size: 24px;
}

.icon-lg {
  font-size: 32px;
}

/* size examples - absolute units */
.icon-16 {
  font-size: 16px;
}

.icon-32 {
  font-size: 32px;
}

.icon-clickable {
  padding: 8px;
}

.icon-clickable:hover {
  border-radius: 50%;
  background-color: #f5f7fa;
  cursor: pointer;
}
.flex-center-rows {
  display: flex;
  align-items: center;
}

.flex-center-columns {
  display: flex;
  flex-direction: column;
  align-items: center;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px #f5f7fa inset !important;
  background-color: #f5f7fa !important;
  background-clip: content-box !important;
}
.flex-space-between-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flex-space-between-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.w-100 {
  width: 100%;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

.errorMessage {
  font-family: 'Open Sans';
  color: #ec685e;
  font-size: 14px;
  line-height: 21px;
  margin-top: 4px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.scrollWrapper {
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
    margin-top: 70px;
    margin-bottom: 15px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(55, 55, 55, 0.3);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
  }
}
